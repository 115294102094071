.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Maintext {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: white;
  padding: 0rem 0.2rem 0.1rem 0.2rem;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  color: black;
  font-family: SM3 TGothic, SuisseIntl;
  /* font-size: 30px; */
  font-size: 1.3rem;
  line-height: 1.1;
  word-break: keep-all;
  margin: 0;
}

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: white;
  padding: 0rem 0.2rem 0.1rem 0.2rem;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  color: black;
  font-family: SM3 TGothic, SuisseIntl;
  /* font-size: 30px; */
  font-size: 1.3rem;
  line-height: 1.1;
  word-break: keep-all;
  margin: 0;
}

@media (max-width: 1280px) {
  .Maintext, .Footer
  {font-size: 1.1rem;}
}

@media (max-width: 800px) {
  .Maintext
  {display: none;}
}

.Hovertext {
  width: 5.9rem;
  text-align: center;
  position: relative;
  background: white;
  padding: 0rem 0.2rem 0.1rem 0.2rem;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  color: black;
  font-family: SM3 TGothic, SuisseIntl;
  font-size: 1.3rem;
  line-height: 1.1;
  word-break: keep-all;
  margin: 0;
}

.Vphover{
  display : none;
}

.Vp{
  justify-content: center;
  display: flex;
  width: 2rem;
  height: 2rem;
  position: fixed;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  border: none;
  background: white;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  color: black;
  font-family: SM3 TGothic, SuisseIntl;
  font-size: 0.5rem;
  line-height: 1.1;
  word-break: keep-all;
  z-index: 997;
  cursor: grab;
}

@media (max-width: 800px) {
  .Vp {display: none;}
}

.Vp:hover + .Vphover{
  display : block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "SM3 TGothic"; 
  src: url("/src/constants/SM3Tgothic-Regular.woff");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("/src/constants/SuisseIntl-Regular.woff");
  unicode-range:  U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+2018-2019;
}

.swiper-pagination-bullet {
  background: #ffffff !important;
}

.swiper{
  --swiper-theme-color:#fff;
  --swiper-navigation-size: 20px;
  }

  @media (min-width: 800px) {
    .swiper {--swiper-navigation-size: 30px;}
}

.closebutton {
  color:black;
  background: white;
  border: none;
  padding: 0;
  font-family: Arial;
  font-size: 1.3rem;
  cursor: grab;
}

@media (max-width: 800px) {
  .closebutton {font-size: 1.1rem;}
}

#modal::-webkit-scrollbar {
 display: none;
}
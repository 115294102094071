.text {
    white-space:pre-wrap;
    word-break: keep-all;
}

.text2 {
    white-space:pre-wrap;
    text-align: center;
    margin-top: 0;
    padding: 0rem 0.5rem 1rem 0.5rem;
}

.MenuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
  }

  .MenuButtonEn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
  }

.menuaccordion {
    padding: 0rem 0.9rem 0rem 0.9rem;
    display: block;
    color: white;
    transition: max-height 0.5s ease-in-out;
    max-height:0;
    background: black;
    font-family: SM3 TGothic, SuisseIntl;
    font-size: 0.9rem;
    line-height: 1.6;
    letter-spacing: 0.03em;
    overflow: hidden;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
}

.dev {

    display: block;
    justify-content: center;
    background: white;
    padding: 0rem 0.9rem 0rem 0.9rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);

    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
}

.menupannel {
    
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: black;
    border-radius: 5px;
    border: 1px solid white;
    /* border: 1px solid rgba(255, 255, 255, 0.8); */
    padding: 0.7rem 0.9rem 0.7rem 0.9rem;
    transition: background 0.5s;

    
    color: white;
    font-family: SM3 TGothic, SuisseIntl;
    font-size: 3vh;
    line-height: 1.5;
    letter-spacing: 0.03em;
    text-align: center;
    word-break: keep-all;

}

.Caption {
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
    cursor: grab;
}

.Caption:hover {
    background: #0094FF;
  }

.Person {
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
    cursor: grab;
}

.somoim {
    cursor: grab;
}

.somoim:hover > p{
    background: #474747;
    z-index: 999;
  }

.Person:hover {
    background: #FFD600;
  }

.Konkuk {
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
}

.Typo {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
}

.Somoim {
    align-self: end;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: white;
    padding: 0rem 0.2rem 0.1rem 0.2rem;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
    color: black;
    font-family: SM3 TGothic, SuisseIntl;
    /* font-size: 30px; */
    font-size: 1.3rem;
    line-height: 1.1;
    word-break: keep-all;
    margin: 0;
}

@media (max-width: 1280px) {
    .MenuButton, .MenuButtonEn, .Caption, .Person, .Konkuk, .Typo, .Somoim
    {font-size: 1.1rem;}
}

@media (max-width: 800px) {
    .Konkuk, .Typo{align-self: end;}
}

@media (max-width: 800px) {
    .Person {align-self: center;}
}